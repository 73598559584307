<template>
	<div>
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="timeQuantum"
						type="daterange"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="month" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top --> 
		<el-row>
		  <el-col :span="4">
				<div>
					<p class="color">销售金额(元)</p>
					<p class="size">
						<span>7,848</span>
						<span>
							<i class="el-icon-caret-bottom"></i>
							<i>0%</i>
						</span>
					</p>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">购买人次(次)</p>
						<p class="size">
							<span>7,848</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>0%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">使用人次(次)</p>
						<p class="size">
							<span>828</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-54.15%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">消耗数量(次)</p>
						<p class="size">
							<span>8,181</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-66.94%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">消耗金额(元)</p>
						<p class="size">
							<span>0 (0分)</span>
							<span>
								<i>0%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">
							<span>套餐剩余总额</span>
							<span></span>
							<span>次数</span>
						</p>
						<p class="size border">
							<span>25,985元</span>
							<span></span>
							<span>687次</span>
						</p>
						<p class="size border">
							<el-button @click="onItemDialog" type="primary" plain size="mini">查看明细</el-button>
						</p>
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- top -->
		<div class="top">
			<div class="left">
				 <span>疗程卡销售分析</span>
				 <el-radio-group v-model="single" size="mini">
				 	 <el-radio-button label="销售过的疗程卡"></el-radio-button>
				 	 <el-radio-button label="所有疗程卡"></el-radio-button>
				 </el-radio-group>
				 <el-popover
				 	placement="bottom"
				 	width="300"
				 	trigger="click">
				 	<div class="look" slot="reference">
				 		<el-button icon="el-icon-s-grid" size="mini"></el-button>
				 	</div>
				 </el-popover>
			</div>
		</div><!-- end top --> 
		<el-table
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}"
			show-summary
			size="small">
			<el-table-column
				prop="date"
				label="疗程套餐名称"
				width="200"
				fixed>
				<template slot-scope="scope">
				   <div @click="handleEdit(scope.$index, scope.row)" class="color">{{ scope.row.date }}</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="date"
				label="所有部门"
				width="150">
			</el-table-column>
			<el-table-column label="日期区段销售及销售分析" align="center">
				<el-table-column
					prop="province"
					label="购买人次(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="销售金额(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="套餐额度(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="含项目数量(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="消耗额(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="消耗次(次)"
					width="120">
				</el-table-column>
		  </el-table-column>
			<el-table-column label="累积整体分析" align="center">
				<el-table-column
					prop="province"
					label="销售总额(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="套餐总额(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="项目总数量(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="总消耗次(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="总消耗额(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="持有人数(人)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="剩余数量(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="剩余额度(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="次数耗率"
					width="120">
				</el-table-column>
				<el-table-column
					prop="province"
					label="过期次数占比"
					width="120">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
		<LookDialog ref="LookDialog"></LookDialog>
		<ItemDialog ref="ItemDialog"></ItemDialog>
	</div>
</template>

<script>
	import LookDialog from './components/look-dialog.vue'
	import ItemDialog from './components/item-dialog.vue'
	export default {
		components: { LookDialog , ItemDialog},
		data() {
			return {
				timeQuantum: '',
				month: '今日',
				single: '销售过的疗程卡',
				tableData: [{
					id: '全店',
					date: '会员卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '银卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '还款',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				}]
			}
		},
		methods:{
			handleEdit(index, row){
				this.$refs.LookDialog.dialogVisible = true
			},
			onItemDialog(){
				this.$refs.ItemDialog.dialogVisible = true
			}
		}
	}
</script>

<style lang="less" scoped>
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
	.left{
		display: flex;
		align-items: center;
		.select{
			display: flex;
			align-items: center;
			.el-input__inner{
				width: 220px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
			margin-right: 10px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	.el-button{
		margin-left: 10px;
	}
	.prompt{
		margin-left: 20px;
	}
	.center{
		display: flex;
		align-items: center;
	}
}
.el-row{
	margin: 30px 0;
	padding: 20px 0;
	border: 1px solid #d3dce6;
	border-radius: 5px;
	.el-col{
		border-right: 1px solid #d3dce6;
		padding: 0 20px;
		height: 92px;
		p{
			margin: 0;
			line-height: 2;
		}
		.color{
			color: #8492a6;
			>span:nth-child(2){
				border-left: 1px solid #999999;
				margin: 0 10px;
			}
		}
		.size>span:first-child{
			font-size: 18px;
		}
		.size>span:last-child{
			color: #30c27c;
			margin-left: 20px;
			i{
				margin-right: 10px;
			}
		}
		.border>span:nth-child(2){
			border-left: 1px solid #000000;
			margin: 0 10px;
		}
		.border>span:nth-child(3){
			margin: 0;
			font-size: 18px;
		}
	}
	.el-col:last-child{
		border-right: none;
	}
}

.el-table {
	.color{
	  color: #20a0ff;
	  cursor: pointer;
	}
}
</style>
