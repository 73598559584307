<template>
  <div class="item-dialog">
    <el-dialog
      :visible.sync="dialogVisible"
      title="套餐项目余额明细"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p>
        <span>剩余总数量：687次</span>
        <span>套餐余额：25985元</span>
        <span>持套餐人数：302人</span>
      </p>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#333333' }"
        border
        size="small"
      >
        <el-table-column prop="name" label="所有项目">
          <template slot="header" slot-scope="scope">
            <el-cascader
              :options="options"
              :props="{ checkStrictly: true }"
              v-model="value"
              size="small"
            >
            </el-cascader>
          </template>
        </el-table-column>
        <el-table-column prop="bu" label="部门"> </el-table-column>
        <el-table-column prop="ren" label="持有人数"> </el-table-column>
        <el-table-column prop="shu" label="剩余数量"> </el-table-column>
        <el-table-column prop="yu" label="余额(元)">
        </el-table-column> </el-table
      ><!-- end table -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      timeQuantum: "",
      month: "今日",
      currentPage4: 1,
      tableData: [
        {
          id: 1,
          name: "[服务]35元肩颈理疗",
          bu: "养生洗",
          ren: "1",
          shu: 1,
          yu: 22,
        },
        {
          id: 2,
          name: "[服务]35元肩颈理疗",
          bu: "养生洗",
          ren: "1",
          shu: 1,
          yu: 22,
        },
        {
          id: 3,
          name: "[服务]35元肩颈理疗",
          bu: "养生洗",
          ren: "1",
          shu: 1,
          yu: 22,
        },
        {
          id: 4,
          name: "[服务]35元肩颈理疗",
          bu: "养生洗",
          ren: "1",
          shu: 1,
          yu: 22,
        },
      ],
      options: [
        {
          value: "zhinan",
          label: "所有项目",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
          ],
        },
        {
          value: "shangpin",
          label: "商品",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
          ],
        },
        {
          value: "fuwu",
          label: "服务",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
          ],
        },
      ],
      value: "zhinan",
    };
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
  > span {
    margin-right: 30px;
  }
}
.el-table {
  margin-top: 20px;
  .el-cascader {
    input {
      background-color: #eef1f6;
    }
    .el-input__inner {
      border-color: #ffffff !important;
    }
    .is-focus > .el-input__inner {
      border-color: #ffffff !important;
    }
  }
}
</style>
